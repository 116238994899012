import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';

const Baloon = styled.div<SpaceProps>`
  position: relative;
  display: inline-block;
  margin: 1.5em 0;
  min-width: 120px;
  max-width: 100%;
  font-size: 14px;
  border-radius: 30px;
  background: #fff;
  border: solid 3px #d8d8d8;
  box-sizing: border-box;
  ${space};

  :before {
    content: '';
    position: absolute;
    bottom: -24px;
    left: 50%;
    margin-left: -15px;
    border: 12px solid transparent;
    border-top: 12px solid #fff;
    z-index: 2;
  }

  :after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -17px;
    border: 14px solid transparent;
    border-top: 14px solid #d8d8d8;
    z-index: 1;
  }
`;

export default Baloon;
