import { navigate } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import InfantSvg from '../../assets/svgs/infant.svg';
import NewbornBabySvg from '../../assets/svgs/newborn-baby.svg';
import UnbornBabySvg from '../../assets/svgs/unborn-baby.svg';
import route from '../../constants/route';
import LayoutContext from '../../contexts/LayoutContext';
import { getIsLoggedIn } from '../../redux/auth/selectors';
import { RootState } from '../../redux/rootReducer';
import {
  close as closeSignUpDialogAction,
  open as openSignUpDialogAction,
} from '../../redux/signUpDialog/actions';
import Box, { ColumnBox, RowBox } from '../Box';
import { OutlinedButton } from '../Button';
import Image from '../Image';
import VerticalLine from '../Line/VerticalLine';
import Text from '../Text';

const TopPcContent = React.memo(() => (
  <>
    <Text fontSize={4} pb={80}>
      お子さまの成長と子育てのお悩みは日進月歩。月齢別でお悩みの内容も変わることもあります。
    </Text>
    <RowBox justifyContent="center">
      <ColumnBox width={0.4} p={[0, 1, 2, 3]}>
        <Box width={1} height="100%" pt={100}>
          <Image name="topServiceLeft1" />
        </Box>
        <Box width={1} height="100%" pb={90}>
          <Image name="topServiceLeft2" />
        </Box>
      </ColumnBox>
      <ColumnBox
        alignItems="center"
        p={[0, 1, 2, 3]}
        ml={[0, 1, 2, 4]}
        mr={[0, 1, 2, 4]}
      >
        <UnbornBabySvg />
        <Text fontSize={4} fontWeight="bold" color="dusty-orange">
          妊娠中
        </Text>
        <VerticalLine bg="dusty-orange" height={62} m={[0, 1, 2, 3]} />
        <NewbornBabySvg />
        <Text fontSize={4} fontWeight="bold" color="dusty-orange">
          新生児
        </Text>
        <VerticalLine bg="dusty-orange" height={62} m={[0, 1, 2, 3]} />
        <InfantSvg />
        <Text fontSize={4} fontWeight="bold" color="dusty-orange">
          乳児
        </Text>
        <VerticalLine bg="dusty-orange" height={96} m={[0, 1, 2, 3]} />
      </ColumnBox>
      <ColumnBox width={0.4} p={[0, 1, 2, 3]}>
        <Box width={1} height="100%" pt={160} pb={90}>
          <Image name="topServiceRight1" />
        </Box>
      </ColumnBox>
    </RowBox>
  </>
));
const PcContent = React.memo(() => (
  <>
    <Text fontSize={4} pb={80} textAlign="center">
      どのコースを選べばいいんだろう？とお悩みの方へ。
      <br />
      例えばこんなサポート対応ができます。
    </Text>
    <Box justifyContent="center" pb={6}>
      <Image name="service" />
    </Box>
  </>
));
const MobileContent = React.memo(() => (
  <>
    <Text fontSize={2} textAlign="center">
      どのコースを選べばいいんだろう？とお悩みの方へ。
      <br />
      例えばこんなサポート対応ができます。
    </Text>
    <Box justifyContent="center" p={1} pt={5} pb={5}>
      <Image name="serviceMobile" />
    </Box>
  </>
));
const TopMobileContent = React.memo(() => (
  <ColumnBox>
    <Box p={[2, 2, 3]}>
      <Text fontSize={3} pb={[20, 40, 80]}>
        お子さまの成長と子育てのお悩みは日進月歩。月齢別でお悩みの内容も変わることもあります。
      </Text>
    </Box>
    <RowBox justifyContent="space-between">
      <ColumnBox alignItems="center" p={4}>
        <UnbornBabySvg />
        <Text fontSize={1} fontWeight="bold" color="dusty-orange">
          妊娠中
        </Text>
        <VerticalLine bg="dusty-orange" height={136} m={2} />
        <NewbornBabySvg />
        <Text fontSize={1} fontWeight="bold" color="dusty-orange">
          新生児
        </Text>
        <VerticalLine bg="dusty-orange" height={136} m={2} />
        <InfantSvg />
        <Text fontSize={1} fontWeight="bold" color="dusty-orange">
          乳児
        </Text>
        <VerticalLine bg="dusty-orange" height={136} m={2} />
        <Text fontSize={1} fontWeight={900} color="dusty-orange">
          + MORE
        </Text>
      </ColumnBox>
      <ColumnBox width={0.75} pt={50}>
        <Box width={1} height="100%">
          <Image name="topServiceMobile1" />
        </Box>
        <Box width={1} height="100%">
          <Image name="topServiceMobile2" />
        </Box>
        <Box width={1} height="100%">
          <Image name="topServiceMobile3" />
        </Box>
      </ColumnBox>
    </RowBox>
  </ColumnBox>
));

export const TopService: React.FC = () => {
  const { isMobile } = React.useContext(LayoutContext);
  return (
    <ColumnBox>{isMobile ? <TopMobileContent /> : <TopPcContent />}</ColumnBox>
  );
};

interface StateProps {
  isLoggedIn: boolean;
}
interface DispatchProps {
  closeSignUpDialog: () => void;
  openSignUpDialog: () => void;
}
type Props = StateProps & DispatchProps;
const Service: React.FC<Props> = ({
  isLoggedIn,
  closeSignUpDialog,
  openSignUpDialog,
}) => {
  const { isMobile } = React.useContext(LayoutContext);
  const handleClick = React.useCallback(() => {
    if (isLoggedIn) {
      navigate(route.account.path);
      closeSignUpDialog();
    } else {
      openSignUpDialog();
    }
  }, [closeSignUpDialog, isLoggedIn, openSignUpDialog]);
  return (
    <ColumnBox>
      {isMobile ? <MobileContent /> : <PcContent />}
      <ColumnBox alignItems="center">
        <OutlinedButton
          onClick={handleClick}
          width={[200, 300, 300]}
          height={[48, 55, 55]}
          label="コースを申し込む"
        />
      </ColumnBox>
    </ColumnBox>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = {
  closeSignUpDialog: closeSignUpDialogAction,
  openSignUpDialog: openSignUpDialogAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Service);
