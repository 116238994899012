import React from 'react';
import About from '../components/About';
import Contact from '../components/Contact';
import FAQ from '../components/Faq';
import HeroHeader from '../components/HeroHeader';
import Layout from '../components/Layout/Layout';
import SEO from '../components/Seo';
import { TopService } from '../components/Service';
import TopSectionWrapper from '../components/TopSectionWrapper';
import route from '../constants/route';
import LayoutContext from '../contexts/LayoutContext';

const IndexPage = () => {
  const { isMobile } = React.useContext(LayoutContext);
  return (
    <Layout isMobile={isMobile}>
      <SEO
        title={route.root.title}
        keywords={route.root.keywords}
        pathname={route.root.path}
      />
      <HeroHeader />
      <TopSectionWrapper bg="white" heading="NOTICE" subtitle="お知らせ">
        当サービスは法人様を通じてご提供しております。ご利用をご検討の法人様はサイト下部よりお問い合わせください。
      </TopSectionWrapper>
      <About />
      <TopSectionWrapper bg="white" heading="SERVICE" subtitle="サポート内容">
        <TopService />
      </TopSectionWrapper>
      {/* <TopSectionWrapper
        bg="very-light-pink"
        heading="CASE STUDY"
        subtitle="お客様の声と事例のご紹介"
      >
        <TopCaseStudy />
      </TopSectionWrapper> */}
      {/* <TopSectionWrapper
        bg="white"
        heading="SUPPORTERS"
        subtitle="総勢20名の経験豊富な医療職がみなさんをサポートします"
      >
        <Supporters />
      </TopSectionWrapper>
      <TopSectionWrapper
        bg="very-light-pink"
        heading="PRICE"
        subtitle="各コースと料金"
      >
        <Price />
      </TopSectionWrapper>
      <TopSectionWrapper bg="white" heading="FLOW" subtitle="ご利用の流れ">
        <Flow />
      </TopSectionWrapper> */}
      <TopSectionWrapper
        bg="very-light-pink"
        heading="FAQ"
        subtitle="よくあるご質問"
      >
        <FAQ />
      </TopSectionWrapper>
      <Contact />
    </Layout>
  );
};

export default IndexPage;
