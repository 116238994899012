import styled from '@emotion/styled';
import {
  color,
  height,
  HeightProps,
  space,
  SpaceProps,
  WidthProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

type VerticalLineProps = ColorProps & HeightProps & SpaceProps & WidthProps;

const VerticalLine = styled.div<VerticalLineProps>`
  width: 2px;
  background-color: black;
  ${color};
  ${height};
  ${space};
`;

export default VerticalLine;
