import React from 'react';
import Box, { ColumnBox } from '../Box';
import { OutlinedButton } from '../Button';
import Line from '../Line';
import Text from '../Text';

const Contact: React.FC = () => (
  <ColumnBox
    bg="off-white"
    width={1}
    p={[40, 50, 50]}
    height={[269, 350, 350]}
    alignItems="center"
    justifyContent="space-between"
  >
    <ColumnBox
      height={[115, 150, 150]}
      alignItems="center"
      justifyContent="space-between"
    >
      <Text
        textAlign="center"
        fontSize={[5, 9, 9]}
        fontWeight={900}
        color="dusty-orange"
        letterSpacing={[1.3, 2, 2]}
      >
        CONTACT US
      </Text>
      <Text fontSize={[0, 4, 4]} fontWeight="bold">
        導入をご検討の法人様はお問い合わせください。
      </Text>
      <Text fontSize={[0, 4, 4]} fontWeight="bold">
        ※個別の産前産後のご相談にはこちらでは対応致しかねますのでご了承ください。
      </Text>
      <Line bg="dusty-orange" width={24} />
    </ColumnBox>
    <a href="https://forms.gle/48JYSigiSXUS154y5" target="blank">
      <OutlinedButton
        width={[200, 300, 300]}
        height={[48, 55, 55]}
        label="お問い合わせ"
      />
    </a>
  </ColumnBox>
);

export default Contact;
