import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'gatsby';
import React from 'react';
import Baloon from '../Baloon';
import Box, { ColumnBox } from '../Box';
import Text from '../Text';

interface Props {
  baloonLabel?: string;
  heading: string;
  label: string;
  pathname?: string;
  url?: string;
}

const OutlinedBoxWithBaloon: React.FC<Props> = ({
  baloonLabel,
  heading,
  label,
  pathname,
  url,
}) => {
  const BoxComponent = () => (
    <ColumnBox
      bg="white"
      border="solid 2px"
      borderColor="dusty-orange"
      borderRadius={4}
      pt={4}
      pb={4}
      width={1}
      height={120}
      position="relative"
    >
      <ColumnBox m="auto" alignItems="center">
        <Text
          color="dusty-orange"
          fontSize={7}
          fontWeight={900}
          letterSpacing={1}
          pb={3}
        >
          {heading}
        </Text>
        <Text fontSize={[3, 5, 5]} fontWeight="bold">
          {label}
        </Text>
      </ColumnBox>
      <Box position="absolute" right={10} top={42}>
        <NavigateNextIcon style={{ fontSize: 32, color: '#707070' }} />
      </Box>
    </ColumnBox>
  );
  return (
    <ColumnBox alignItems="center">
      <Box pb={3}>
        {baloonLabel && (
          <Baloon>
            <Text
              fontSize={3}
              fontWeight="bold"
              pt={12}
              pb={12}
              pl={[30, 60, 100]}
              pr={[30, 60, 100]}
            >
              {baloonLabel}
            </Text>
          </Baloon>
        )}
      </Box>
      {pathname ? (
        <Link to={pathname} style={{ width: '100%' }}>
          <BoxComponent />
        </Link>
      ) : (
        <a href={url} style={{ width: '100%' }} target="blank">
          <BoxComponent />
        </a>
      )}
    </ColumnBox>
  );
};

export default OutlinedBoxWithBaloon;
