import React from 'react';
import LayoutContext from '../../contexts/LayoutContext';
import Box from '../Box';
import Image from '../Image';
import Text from '../Text';

const PcContent = React.memo(() => (
  <>
    <Image name="heroHeader" />
    <Box position="absolute" bottom={0} left={0} p={['5%', '9%', '10%']}>
      <Text
        fontSize={[5, 7, 36]}
        fontWeight="bold"
        pb={[30, 45, 60]}
        lineHeight={1.67}
      >
        あなたの育児に
        <br />
        寄り添う医療職
      </Text>
    </Box>
  </>
));
const MobileContent = React.memo(() => <Image name="heroHeaderMobile" />);

const HeroHeader: React.FC = () => {
  const { isMobile } = React.useContext(LayoutContext);
  return (
    <Box position="relative" width={1} height="auto">
      {isMobile ? <MobileContent /> : <PcContent />}
    </Box>
  );
};

export default HeroHeader;
