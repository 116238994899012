import React from 'react';
import { ColumnBox, RowBox } from '../Box';
import Image from '../Image';
import Text from '../Text';

const params = [
  {
    description:
      '現場で数々の母子を見てきた医療職が、個別に育児のサポートをします。',
    imageName: 'feature1',
    index: '1',
    title: ['悩みに専門家が', '答えます'],
  },
  {
    description: 'LINEを使用するので、24時間いつでも困った時に相談可能です。※',
    imageName: 'feature2',
    index: '2',
    title: ['いつでも', '相談できます'],
  },
  {
    description:
      '相談はもちろんのこと、育児記録に基づき定期的にアドバイスも行います。',
    imageName: 'feature3',
    index: '3',
    title: ['あなたの育児に', '伴走します'],
  },
];

const Feature: React.FC = () => (
  <>
    <RowBox width={1} justifyContent="space-around" flexWrap="wrap">
      {params.map(param => (
        <ColumnBox key={param.index} alignItems="center" width={[1, 1, 286]}>
          <ColumnBox
            width={[120, 160, 160]}
            height="auto"
            m="auto"
            pb={4}
            justifyContent="center"
          >
            <Image
              name={param.imageName as 'feature1' | 'feature2' | 'feature3'}
            />
          </ColumnBox>
          <Text
            fontSize={7}
            fontWeight={900}
            color="dusty-orange"
            letterSpacing={1.2}
            pb={4}
          >
            {param.index}
          </Text>
          <Text
            fontSize={7}
            fontWeight="bold"
            lineHeight={1.33}
            textAlign="center"
            pb={4}
          >
            {param.title[0]}
            <br />
            {param.title[1]}
          </Text>
          <Text
            fontSize={3}
            fontWeight={500}
            lineHeight={1.57}
            textAlign="center"
            pl={[36, 3, 3]}
            pr={[36, 3, 3]}
            pb={[90, 90, 5]}
          >
            {param.description}
          </Text>
        </ColumnBox>
      ))}
    </RowBox>

    <ColumnBox alignItems="center">
      <Text fontSize={1} fontWeight={500} lineHeight={1.5}>
        ※ご回答には時間をいただく場合もございます。詳細はよくあるご質問をご覧ください。
      </Text>
    </ColumnBox>
  </>
);

export default Feature;
