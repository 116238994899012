import React from 'react';
import LayoutContext from '../../contexts/LayoutContext';
import styled from '../../styles/styled';
import Box, { ColumnBox, RowBox } from '../Box';
import Image from '../Image';

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: ${props => `30px solid ${props.theme.colors['dusty-orange']}`};
`;

const Scene: React.FC = () => {
  const { isMobile } = React.useContext(LayoutContext);
  if (isMobile) {
    return (
      <Box height="auto" width={1}>
        <Image name="aboutSceneMobile" />
      </Box>
    );
  }
  return (
    <ColumnBox alignItems="center" justifyContent="space-around">
      <RowBox width={1} justifyContent="center" p={4}>
        <Box height="auto" width={800}>
          <Image name="aboutSceneBefore" />
        </Box>
      </RowBox>
      <Triangle />
      <RowBox width={1} justifyContent="center" p={4}>
        <Box height="auto" width={600}>
          <Image name="aboutSceneUse" />
        </Box>
      </RowBox>
      <Triangle />
      <RowBox width={1} justifyContent="center" p={4}>
        <Box height="auto" width={800}>
          <Image name="aboutSceneAfter" />
        </Box>
      </RowBox>
    </ColumnBox>
  );
};

export default Scene;
