import styled from '@emotion/styled';
import {
  color,
  height,
  HeightProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

type LineProps = ColorProps & SpaceProps & HeightProps & WidthProps;

const Line = styled.div<LineProps>`
  height: 2px;
  background-color: black;
  ${color};
  ${space};
  ${height};
  ${width};
`;

export default Line;
