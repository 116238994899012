import React from 'react';
import Box, { ColumnBox, RowBox } from '../Box';
import { FlatButton, OutlinedButton } from '../Button';
import Text from '../Text';

const params = [
  {
    answer:
      '病院などの医療機関で一定年数以上の経験を積んだ助産師、看護師、保健師です。登録している医療職は運営会社が審査し、適切な健康指導ができると判断した方々です。',
    key: 'q-1',
    question: '担当する医療職はどのような方ですか？',
  },
  {
    answer:
      '対応できる時間は平日、土日・祝日を問わず10:00〜18:00になります。原則24時間以内にお答えさせていただきますが、迅速にお答えすることに重きを置いておりません。',
    key: 'q-2',
    question: '相談の回答はすぐにもらえますか？',
  },
  {
    answer:
      '当サービスはチャットのみの相談となっております。ただし、写真の送付は可能ですので、必要な場合には写真を見ながらご相談に対応させていただきます。',
    key: 'q-3',
    question: 'LINEの無料電話やビデオ通話で相談することはできますか？',
  },
  {
    answer:
      '当サービスは医療的な診断・判断は行なっておりませんので、ご相談にお答えすることはできません。',
    key: 'q-4',
    question:
      '朝から発熱があり、受診するか迷っています。こちらで相談できますか？',
  },
];

const FAQ: React.FC = () => (
  <ColumnBox justifyContent="center" alignItems="center">
    {params.map(param => (
      <ColumnBox
        key={param.key}
        width={1}
        bg="white"
        border="solid 2px"
        borderColor="dusty-orange"
        borderRadius={4}
        mt={2}
        mb={2}
      >
        <RowBox alignItems="center">
          <Box p={12}>
            <FlatButton width={36} height={36} label="Q" />
          </Box>
          <Text
            pr={12}
            fontSize={3}
            fontWeight="bold"
            mt="auto"
            mb="auto"
            mr="auto"
            lineHeight={1.71}
          >
            {param.question}
          </Text>
        </RowBox>
        <RowBox>
          <Box pl={12} pr={12} pb={12}>
            <OutlinedButton width={36} height={36} label="A" />
          </Box>
          <Text
            pr={12}
            pb={12}
            fontSize={3}
            fontWeight={500}
            mt="auto"
            mb="auto"
            mr="auto"
            textAlign="justify"
            lineHeight={1.5}
          >
            {param.answer}
          </Text>
        </RowBox>
      </ColumnBox>
    ))}
  </ColumnBox>
);

export default FAQ;
