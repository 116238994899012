import React from 'react';
import { ColumnBox } from '../Box';
import Line from '../Line';
import Text from '../Text';

interface OwnProps {
  bg: 'very-light-pink' | 'white';
  heading: string;
  subtitle: string;
}

const TopSectionWrapper: React.FC<OwnProps> = ({
  children,
  bg,
  heading,
  subtitle,
}) => (
  <ColumnBox
    bg={bg}
    width={1}
    pt={[48, 80, 80]}
    pb={[48, 80, 80]}
    pl={3}
    pr={3}
    justifyContent="space-between"
  >
    <ColumnBox
      width={[1, 3 / 4, 2 / 3]}
      justifyContent="space-between"
      ml="auto"
      mr="auto"
    >
      <ColumnBox
        height={[90, 150, 150]}
        justifyContent="space-between"
        pb={[35, 40, 40]}
      >
        <Text
          color="dusty-orange"
          fontSize={[5, 9, 9]}
          fontWeight={900}
          letterSpacing={2}
        >
          {heading}
        </Text>
        <Text fontSize={[0, 4, 4]} fontWeight="bold">
          {subtitle}
        </Text>
        <Line width={24} bg="dusty-orange" />
      </ColumnBox>
      {children}
    </ColumnBox>
  </ColumnBox>
);

export default TopSectionWrapper;
