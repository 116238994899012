import { Link } from 'gatsby';
import React from 'react';
import route from '../../constants/route';
import LayoutContext from '../../contexts/LayoutContext';
import Box, { ColumnBox, RowBox } from '../Box';
import { OutlinedButton } from '../Button';
import Image from '../Image';
import Line from '../Line';
import OutlinedBoxWithBaloon from '../OutlinedBoxWIthBaloon';
import Text from '../Text';

const bg = 'very-light-pink';
const heading = 'ABOUT';
const subtitle = '「育児ケアオンライン」とは？';

const About: React.FC = () => {
  const { isMobile } = React.useContext(LayoutContext);
  return (
    <ColumnBox
      bg={bg}
      width={1}
      pt={[48, 80, 80]}
      pb={[48, 80, 80]}
      pl={3}
      pr={3}
      alignItems="center"
      justifyContent="space-between"
    >
      <RowBox
        ml="auto"
        mr="auto"
        width={[1, 3 / 4, 2 / 3]}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <ColumnBox justifyContent="flex-start" width={[1, 1, 1, 1 / 2]}>
          <ColumnBox
            height={[90, 150, 150]}
            justifyContent="space-between"
            pb={[35, 40, 40]}
          >
            <Text
              color="dusty-orange"
              fontSize={[5, 9, 9]}
              fontWeight={900}
              letterSpacing={2}
            >
              {heading}
            </Text>
            <Text fontSize={[0, 4, 4]} fontWeight="bold">
              {subtitle}
            </Text>
            <Line width={24} bg="dusty-orange" />
          </ColumnBox>
          <ColumnBox justifyContent="space-around">
            <Text
              fontSize={3}
              fontWeight={500}
              textAlign="justify"
              pb={[2, 2, 3]}
              pr={[3, 3, 4]}
            >
              育児ケアオンラインは、お子さまや母体についての質問やお悩みを、専門的な知識を持つ医療職（助産師、看護師、保健師）にLINEで気軽に相談できるサービスです。
            </Text>
            {!isMobile && (
              <Box pt={[2, 2, 3]}>
                <Link to={route.about.path}>
                  <OutlinedButton width={168} height={40} label="詳しく見る" />
                </Link>
              </Box>
            )}
          </ColumnBox>
        </ColumnBox>
        <ColumnBox
          width={[1, 1, 1, 1 / 2]}
          height="100%"
          minWidth={300}
          p={[4, 6, 6, 4]}
        >
          <Image name="topAbout" />
        </ColumnBox>
      </RowBox>
      {isMobile && (
        <Link to={route.about.path}>
          <OutlinedButton
            width={[200, 300, 300]}
            height={[48, 55, 55]}
            label="詳しく見る"
          />
        </Link>
      )}
      <Box width={[1, 3 / 4, 2 / 3]} pt={3}>
        <OutlinedBoxWithBaloon
          baloonLabel={
            isMobile
              ? ''
              : '育児ケアオンラインが様々なメディアで紹介されています'
          }
          url="https://com-med.co.jp/#news"
          heading="MEDIA"
          label="メディア掲載情報"
        />
      </Box>
    </ColumnBox>
  );
};

export default About;
